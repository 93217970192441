// src/context/MenuContext.js
import React, { createContext, useState, useContext } from 'react';
import ReactGA from "react-ga4";

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children, venueId }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
    //tracking toggle menu event
    ReactGA.event({
      category: "Navigation",
      action: "dp_hamburger_menu_tap",
      label: menuOpen ? "Close Menu" : "Open Menu",
      venueId: venueId
    });
  }

  return (
    <MenuContext.Provider value={{ menuOpen, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
};