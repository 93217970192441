import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import ReactGA from "react-ga4";
import SpotifyPlayer from '../utility/SpotifyPlayer'; // Import SpotifyPlayer
import MusicPlayer from '../utility/MusicPlayer';
import headerLogo from '../../assets/new-menu-icon-2.png';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Styled Components for Page Layout
const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  color: #FFFFFF;
  background-color: #121217;
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden; // Prevent horizontal overflow

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 4%;
  box-sizing: border-box; // Include padding in element's total width and height
  padding-left: 5rem;

   @media (max-width: 768px) {
    flex: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top:3rem;
    padding-bottom:0.1rem;
    max-height: 80%;
  }
`;

const RightContainer = styled.div`
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-left: 1px solid #2E2E2E;
  margin-top: 4%;
  box-sizing: border-box; // Include padding in element's total width and height

  @media (max-width: 768px) {
    flex: 1;
    border-left: none;
    border-top: 1px;
    padding-top: 0rem;
  }
`;

const UpNextHeader = styled.h2`
  position: sticky;
  top: 1rem; // Adjust this value to ensure it stays below the main header
  background-color: #121217; // Match the page background
  z-index: 5;
  padding: 0.5rem 0;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
  background-color: #121217; // Ensure header background color matches the page
  z-index: 10; // Ensure header stays on top
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
  }

  .logo-text {
    font-size: 1.31rem;
    font-weight: bold;
    color: #FFFFFF; // Default color for the "Mutefly" part
  }

  .player-text {
    font-size: 1.44rem;
    color: #38E078; // Green color for the "Player" part
    font-weight: lighter; // Thinner font-weight
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 5rem;
`;

const SearchInput = styled.input`
  background-color: #2E2E2E;
  color: #FFFFFF;
  border: none;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  outline: none;

  &::placeholder {
    color: #999999;
  }
`;

const UserProfile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('https://cdn.usegalileo.ai/stability/2560c208-d4b2-476e-b425-e5d98ee66e98.png');
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem 1.5rem 2rem; // Adjust padding to account for fixed header
  flex: 1;
`;

const SongListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const SongItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 0.5rem;
  gap: 1rem;
`;

const SongDetails = styled.div`
  display: flex;
  gap: 1rem;
`;

const SongThumbnail = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 0.75rem;
  background-size: cover;
  background-image: url(${props => props.url});
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SongTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0;
`;

const SongArtist = styled.p`
  font-size: 0.875rem;
  color: #999999;
  margin: 0;
  padding-top: 0.5rem;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
`;

const PageNumber = styled.button`
  background-color: #2E2E2E;
  color: #FFFFFF;
  border: none;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: #019863;
  }
`;

async function getUserProfile(accessToken) {
  const response = await fetch('https://api.spotify.com/v1/me', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.json();
}

const SongList = ({ queue }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentItems = queue.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = queue;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(queue.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {currentItems.map((song, index) => (
        <SongItem key={index}>
          <SongDetails>
            <SongThumbnail url={song.album.images[0].url} />
            <SongInfo>
              <SongTitle>{song.name}</SongTitle>
              <SongArtist>{song.artists[0].name}</SongArtist>
            </SongInfo>
          </SongDetails>
        </SongItem>
      ))}
    </>
  );
};

function VenuePlayer() {
  const [songQueueData, setSongQueueData] = useState([]);
  const [token, setToken] = useState('');
  const [refresh_token, setRefresh_token] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const venueId = new URLSearchParams(window.location.search).get('venueId');

  useEffect(() => {

    let refreshTokenInterval;

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const fetchToken = async () => {
      try {

        try {
          const response = await fetch(`${backendUrl}/api/venue/tokens/${venueId}`);
          const data = await response.json();

          if (response.ok) {
            const accessToken = data.accessToken;
            const refreshToken = data.refreshToken;
            // console.log('Access Tokenn:', data.accessToken);
            // console.log('Refresh Tokenn:', data.refreshToken);

            if (refreshToken) {
              const response = await axios.get(`${backendUrl}/api/spotify/refresh_token/${venueId}?refresh_token=${refreshToken}`);
              setToken(response.data.access_token);
              // console.log("token: " + response.data.access_token);
              // localStorage.setItem('access_token', response.data.access_token);
            } else {
              //todo: need some communication here
              window.location.href = `/venuelogin?venueId=${venueId}`;
            }

            // Start the interval to refresh token every 50 minutes
            if (refreshToken) {
              refreshTokenInterval = setInterval(async () => {
                try {
                  setTokenRefreshed(true);
                } catch (error) {
                  console.error('Error refreshing token:', error);
                  window.location.href = `/venue?venueId=${venueId}`;
                }
              }, 50 * 60 * 1000); // 50 minutes in milliseconds
            }
          } else {
            console.error('Failed to fetch tokens:', data.error);
          }
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }

        // const accessToken = localStorage.getItem('access_token');
        // const refreshToken = localStorage.getItem('refresh_token');
        // if (accessToken) {
        //   setToken(accessToken);
        // } else if (refreshToken) {
        //   const response = await axios.get(`${backendUrl}/api/spotify/refresh_token/${venueId}`, { refresh_token: refreshToken });
        //   setToken(response.data.access_token);
        //   localStorage.setItem('access_token', response.data.access_token);
        // } else {
        //   window.location.href = `/login?venueId=${venueId}`;
        // }


      } catch (error) {
        console.error('Error fetching or refreshing token:', error);
        window.location.href = `/venue?venueId=${venueId}`;
      }
    };

    // window.location.href = `${backendUrl}/api/spotify/login/${venueId}`;

    if (!venueId) {
      console.error('No venueId found in the URL');
      window.location.href = '/venuelogin';
    }

    fetchToken();

    return () => clearTimeout(refreshTokenInterval);  // Clear the timeout when the component unmounts
  }, []);

  const reloadParentPage = () => {
    shouldReload(true);
    window.location.reload();
  };

  useEffect(() => {
    const handleSendToQueue = async (songRec) => {
      if (!songRec) return;
      const timestamp = new Date().toISOString();
      const response = await fetch(`${backendUrl}/queue/add/${venueId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ song: [songRec] }),
      });
      if (response.status === 200) {
        console.log("Song added! Keep the vibe going.");
      } else {
        console.log("Error adding song to queue.");
      }
    };
    const fetchSongQueueData = () => {
      axios.get(`${backendUrl}/playerqueue/${venueId}`)
        .then(response => {
          setSongQueueData(response.data);
          if (response.data.length === 1) {
            //fetchRecommendation(response.data[0]);
            // fetchRadio(response.data[0]);
          }
        })
        .catch(error => {
          console.error('Error fetching song queue:', error);
        });
    };

    const fetchRecommendation = async (lastPlayed) => {
      const seed_artists = lastPlayed.artists[0].id;
      const seed_track = lastPlayed.id;
      try {
        const response = await axios.get(`${backendUrl}/api/spotify/recommendation?seed_artists=${seed_artists}&seed_tracks=${seed_track}`);
        handleSendToQueue(response.data);
      } catch (error) {
        console.error('Error fetching recommendation:', error);
      }
    };

    const fetchRadio = async (lastPlayed) => {
      const songName = lastPlayed.name;
      const artistName = lastPlayed.artists[0].name;
      const searchQuery = songName+" Radio";
      try {
        //backend call which fetches the radio, then finds the second song from the playlist, returns, adds it to the queue
        const response = await axios.get(`${backendUrl}/api/spotify/radio?searchQuery=${searchQuery}&artistName=${artistName}`);
        handleSendToQueue(response.data);
      } catch (error) {
        console.error('Error fetching radio:', error);
      }
    }

    if (!venueId) {
      console.error('No venueId found in the URL');
      window.location.href = '/venuelogin';
    }

    fetchSongQueueData();
    const intervalId = setInterval(fetchSongQueueData, 5000);
    return () => clearInterval(intervalId);
  }, [token]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const profile = await getUserProfile(token);
      if (profile.images && profile.images.length > 0) {
        setProfileImage(profile.images[0].url);
      }
    };

    fetchUserProfile();
  }, [token]);

  return (
    <PageContainer>
      <Header>
        <Logo>
          {/* <img src={headerLogo} alt="Mutefly Logo" style={{ width: '2rem', height: '2rem' }} /> */}
          <h2 className="logo-text">Mutefly.</h2><h2 className="player-text">Player</h2>
        </Logo>
        <SearchContainer>
          {/* <SearchInput placeholder="Search" /> */}
          <UserProfile style={{ backgroundImage: `url(${profileImage})` }} />
        </SearchContainer>
      </Header>
      <LeftContainer>
        <MusicPlayer token={token} queue={songQueueData} venueId={venueId} tokenRefreshed={tokenRefreshed} reloadParentPage={reloadParentPage} refreshToken={refresh_token}/>
      </LeftContainer>
      <RightContainer>
        <UpNextHeader>Up Next</UpNextHeader>
        <SongListContainer>
          <SongList queue={songQueueData} />
        </SongListContainer>
      </RightContainer>
    </PageContainer>
  );
}

export default VenuePlayer;