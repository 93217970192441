import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #121217;
  padding: 20px;
  border-radius: 8px;
`;

const NowPlaying = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CoverImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const TrackInfo = styled.div`
  color: #fff;
`;

const TrackName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const TrackArtist = styled.div`
  font-size: 14px;
  color: #9ea3b8;
`;

const ControlButton = styled.button`
  background-color: #1db954;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: #3e3e3e;
    cursor: not-allowed;
  }
`;

const SpotifyPlayer = ({ token, queue }) => {
  const track = {
    name: "",
    album: {
      images: [
        { url: "" }
      ]
    },
    artists: [
      { name: "" }
    ]
  };

  const [player, setPlayer] = useState(undefined);
  const [currentTrack, setCurrentTrack] = useState(track);
  const [is_paused, setPaused] = useState(false);
  const [is_active, setActive] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [queueIndex, setQueueIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);

  const playerRef = useRef(null);
  const queueRef = useRef(queue);
  const queueIndexRef = useRef(queueIndex);
  const deviceIdRef = useRef(deviceId);

  useEffect(() => {
    deviceIdRef.current = deviceId;
  }, [deviceId]);

  useEffect(() => {
    queueRef.current = queue;
  }, [queue]);

  useEffect(() => {
    queueIndexRef.current = queueIndex;
  }, [queueIndex]);

  useEffect(() => {
    if (!token || playerRef.current) return;

    const script = document.createElement('script');
    script.src = 'https://sdk.scdn.co/spotify-player.js';
    script.async = true;
    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
      const newPlayer = new window.Spotify.Player({
        name: 'Web Playback SDK',
        getOAuthToken: cb => { cb(token); },
        volume: 0.5
      });

      playerRef.current = newPlayer;
      setPlayer(newPlayer);

      newPlayer.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id);
        setDeviceId(device_id);
        setIsReady(true);
      });

      newPlayer.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
        setIsReady(false);
      });

      newPlayer.addListener('player_state_changed', _.debounce((state) => {
        if (!state) return;

        setCurrentTrack(state.track_window.current_track);
        setPaused(state.paused);
        setCurrentPosition(state.position);
        console.log('Player state changed:', state);

        newPlayer.getCurrentState().then(state => {
          setActive(state !== null);
        });

        if (state.paused && state.position === 0 && state.track_window.previous_tracks.length > 0) {
          console.log("handle next track being called");
          handleNextTrack();
        }
      }, 500));

      newPlayer.setName("Mutefly").then(() => {
        console.log('Player name updated!');
      });

      newPlayer.addListener('initialization_error', ({ message }) => {
        console.error('Initialization error:', message);
      });

      newPlayer.addListener('authentication_error', ({ message }) => {
        console.error('Authentication error:', message);
      });

      newPlayer.addListener('account_error', ({ message }) => {
        console.error('Account error:', message);
      });

      newPlayer.addListener('playback_error', ({ message }) => {
        console.error('Playback error:', message);
      });

      newPlayer.connect().then(success => {
        if (success) {
          console.log('The Web Playback SDK on Mutefly successfully connected to Spotify!');
        } else {
          console.error('The Web Playback SDK could not connect to Spotify.');
        }
      });
    };

    return () => {
      if (playerRef.current) {
        playerRef.current.disconnect();
      }
    };
  }, [token]);

  useEffect(() => {
    if (isReady && deviceId && queue.length > 0) {
      console.log("Useffect playing track");
      playTrack(queue[0].uri);
    }
  }, [isReady, deviceId]);

  const playTrack = (uri, position = 0) => {
    const deviceIdCurrent = deviceIdRef.current;
    if (!deviceIdCurrent) {
      console.error('No device ID available');
      return;
    }
    playerRef.current._options.getOAuthToken(accessToken => {
      fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceIdCurrent}`, {
        method: 'PUT',
        body: JSON.stringify({ uris: [uri], position_ms: position }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      }).then(() => {
        console.log('Track playing');
      }).catch(error => {
        console.error('Error playing track:', error);
      });
    });
  };

  const handlePlayPause = () => {
    if (playerRef.current && isReady) {
      playerRef.current.togglePlay().then(() => {
        console.log('Toggled playback!');
        playerRef.current.getCurrentState().then(state => {
          console.log('Current state after toggle:', state);
          setPaused(state?.paused);
        }).catch(error => {
          console.error('Error getting current state:', error);
        });
      }).catch(error => {
        console.error('Error toggling playback:', error);
      });
    } else {
      console.error('Player is not initialized or not ready.');
    }
  };

  const handleNextTrack = useCallback(_.debounce(() => {
    setQueueIndex((prevQueueIndex) => {
      const latestQueue = queueRef.current; // Use the latest value of queue
      console.log("handle next track called");
      console.log("queue index:" + prevQueueIndex + " queue.length:" + latestQueue.length);
      if (prevQueueIndex < latestQueue.length - 1) {
        const nextIndex = prevQueueIndex + 1;
        console.log("nextIndex: " + nextIndex);
        setCurrentPosition(0);
        playTrack(latestQueue[nextIndex].uri);

        // Remove the played song from the backend queue
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/queue/remove`, { index: prevQueueIndex })
          .then(response => {
            console.log('Song removed from queue:', response.data);
          })
          .catch(error => {
            console.error('Error removing song from queue:', error);
          });

        return nextIndex-1;
      }
      return prevQueueIndex; // Return the previous index if no update is needed
    });
  }, 500), []);

  const handlePreviousTrack = () => {
    if (queueIndex > 0) {
      setQueueIndex(queueIndex - 1);
      setCurrentPosition(0);
      playTrack(queue[queueIndex - 1].uri);
    }
  };

  return (
    <PlayerContainer>
      <NowPlaying>
        <CoverImage src={currentTrack.album.images[0]?.url} alt="Now Playing" />
        <TrackInfo>
          <TrackName>{currentTrack.name}</TrackName>
          <TrackArtist>{currentTrack.artists[0]?.name}</TrackArtist>
        </TrackInfo>
      </NowPlaying>
      <div>
        <ControlButton onClick={handlePreviousTrack} disabled={!isReady}>
          &lt;&lt;
        </ControlButton>
        <ControlButton onClick={handlePlayPause} disabled={!isReady}>
          {is_paused ? "PLAY" : "PAUSE"}
        </ControlButton>
        <ControlButton onClick={handleNextTrack} disabled={!isReady}>
          &gt;&gt;
        </ControlButton>
      </div>
    </PlayerContainer>
  );
};

export default SpotifyPlayer;
