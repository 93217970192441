import React, { useEffect } from "react";
import styled from "styled-components";
import backgroundimage1 from "../../assets/aboutUs.png";
import backgroundimage2 from "../../assets/aboutUs1.png";
import ReactGA from "react-ga4";

const AboutUs = ({ className, venueId }) => {
  useEffect(() => {
    // Log page view event
    ReactGA.event('au_view', {
      category: 'AboutUs',
      action: 'au_view',
      label: venueId
    });

    // Log scroll event
    const handleScroll = () => {
      const scrollDepth = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight) * 100;
      ReactGA.event('au_scroll', {
        category: 'AboutUs',
        action: 'au_scroll',
        label: venueId,
        scroll_depth: scrollDepth
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [venueId]);
  
  return (
    <BackgroundContainer>
      <main className={className}>
        <header className="header">
        </header>
        <section className="what-we-do-section">
          <h2 className="hero-title" style={{ marginBottom:'20px' }}>Welcome to,<br/> Mutefly.</h2>
          <ContentContainer>
          <h3 className="section-title" style={{ padding: '0px 50px 0px', marginTop:'40px' }}>What we do</h3>
          <div className="content">
            <DescriptionWrapper>
              <p>
                The groundbreaking platform set to revolutionize how you experience music in public venues letting you become
                <br /> <i><b>~a partner in vibe~</b></i><br />
                <br />Picture this: you walk into your favorite café, restaurant, or pub, and you’re in control of the vibe. With Mutefly, you can jam out to your favorite tracks in real-time, creating a personalized atmosphere that makes every visit unforgettable.
              </p>
            </DescriptionWrapper>
            <DescriptionWrapperRight>
              <p>
                As the first-of-its-kind product, Mutefly is creating a new era of personalized, interactive music experiences that redefine how we enjoy our time outside the comfort of our homes.
                <br />
                <br />Join us in this innovative journey, where technology meets fun, and every beat counts towards creating an experience tailored just for you. Mutefly is your companion in setting the perfect mood, every time, everywhere.
              </p>
            </DescriptionWrapperRight>
            <DescriptionWrapper>
              <p>
                For venue owners, Mutefly offers a game-changing way to elevate your space. List your venue with us and let your patrons take the reins of the playlist, ensuring an energized and engaging environment without any hassle of managing the music.
                <br />
                <br />We're committed to making your venue the go-to spot for memorable moments, with seamless integration and effortless control over your music vibe.
              </p>
            </DescriptionWrapper>
          </div>
          <h3 className="section-title" style={{ padding: '0px 50px 0px',marginTop:'20px' }}>Our Journey</h3>
          {/* <h4 className="subheader">From College Roommates to Innovators</h4> */}
          <div className="content">
            <DescriptionWrapper>
              <p>Mutefly’s story began in the hazy days of college, where three roommates—Anant, Sourav, and Harsh—dreamed up a solution to a familiar problem.</p>
            </DescriptionWrapper>
            <DescriptionWrapperRight>
              <p>Fast forward a year later, scattered across different cities, the trio reunited at Sourav’s place for a weekend getaway. Amidst laughter and nostalgia, that initial conversation resurfaced. Fueled by passion and a shared vision, they decided to turn their idea into reality. And just two months later, Mutefly was born.</p>
            </DescriptionWrapperRight>
          </div>
          <h3 className="section-title" style={{ padding: '0px 50px 0px',marginTop:'20px' }}>Meet the Founders</h3>
          <div className="content">
            <DescriptionWrapper>
              <p><strong>Sourav</strong>’s simple desire to control the music in a bar. His unwavering passion for music and keen technical acumen form the very essence of Mutefly. Sourav not only envisions but also executes, translating ideas into tangible innovations that resonate with our users.</p>
            </DescriptionWrapper>
            <DescriptionWrapperRight>
              <p><strong>Anant</strong> embodies the spirit of pushing boundaries and challenging norms. From that fateful bar conversation to co-founding Mutefly, his sharp intellect and bold ideas propel us forward. Anant ensures that Mutefly remains at the forefront of innovation, constantly evolving to meet the ever-changing landscape of music technology.</p>
            </DescriptionWrapperRight>
            <DescriptionWrapper>
              <p><strong>Harsh</strong> brings a pragmatic approach and boundless enthusiasm to Mutefly. His expertise in data analytics and strategic leadership has been instrumental in shaping our growth trajectory. From laying the groundwork to scaling operations, Harsh’s dedication ensures that Mutefly operates with precision and efficiency.</p>
            </DescriptionWrapper>
          </div>
          </ContentContainer>
        </section>
      </main>
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-image: url(${backgroundimage1});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 1);
  margin-top:50px;


  @media (max-width: 768px) {
    background-image: url(${backgroundimage2});
    background-size: contain;
    background-repeat: repeat-y;
  }
`;

const ContentContainer = styled.div`
`
const DescriptionWrapper = styled.section`
  color: #fff;
  text-align: left;
  font: 500 16px/23px;
  max-width: 800px;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0px 30px 0px 0px; //padding: <top> <right> <bottom> <left>;
    margin: 0px 30px; //margin: <top> and <bottom>, <right> and <left>;
  }
`;

const DescriptionWrapperRight = styled.section`
  color: #fff;
  text-align: right;
  font: 500 16px/23px;
  max-width: 800px;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0px 0px 0px 30px;
    margin: 0px 30px;
  }
`;

const StyledAboutUs = styled(AboutUs)`
  .header-title {
    padding: 20px 60px 10px;
    margin: 0;
    color: #fff;
  }

  .hero-title {
    font-size: 28px;
    padding: 10px 50px 10px;
    background-color: rgba(0, 0, 0, 0);

    margin: 40px 0;
    color: #fff;
  }

  .what-we-do-section {
    width: 100%;
    font-size: 20px;
    background-color: transparent;
  }

  .section-title {
    padding: 20px 50px 0px;
    margin: 40px 0px 0px;
    color: #fff;
  }

  .content {
    padding: 0px 20px 20px 20px;
    color: #fff;
    font-weight: 400;
    line-height: 1.6;
    background-color: transparent;
  }
  .p{
  margin:20px;
  }

  .subheader {
    font-size: 18px;
    color: #fff;
    padding: 10px 60px 10px;
    margin: 10px 0;
    text-align: center;
  }
`;

export default StyledAboutUs;