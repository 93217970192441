// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Login.css';
// import { useHistory } from 'react-router-dom';

// function Login() {
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [username, setUsername] = useState('');
//   const [otpSent, setOtpSent] = useState(false);
//   const [isOtpVerified, setIsOtpVerified] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const history = useHistory();
  
//   useEffect(() => {
//     if (isLoggedIn) {
//       console.log("history: "+history);
//       history.push('/search');
//     }
//   }, [isLoggedIn, history])

//   const requestOtp = async () => {
//     try {
//       await axios.post('/api/auth/requestOtp', { mobileNumber });
//       setOtpSent(true);
//     } catch (error) {
//       console.error('Error requesting OTP:', error.response.data);
//     }
//   };

//   const verifyOtp = async () => {
//     try {
//       const response = await axios.post('/api/auth/verifyOtp', { mobileNumber, otp });
//       if(response.data.token) {
//         console.log(response.data.message);
//         localStorage.setItem('userToken', response.data.token); // Store token
//         setIsOtpVerified(true);
//         // console.log(history);
//         // history.replace('/search');
//       }
//     } catch (error) {
//       console.error('Error verifying OTP:', error.response);
//     }
//   };

//   const submitUsername = async () => {
//     try {
//       const response = await axios.post('/api/auth/submitUsername', { mobileNumber, username });
//       localStorage.setItem('userToken', response.data.token); // Store token
//       setIsLoggedIn(true);
//       console.log("Redirecting to search...");
//       history.push('/search');
//       window.location.href = '/search';
//     } catch (error) {
//       console.error('Error submitting username:', error.response.data);
//     }
//   };

//   return (
//     <div className="login">
//       <div className="login-container">
//         <input
//           type="text"
//           placeholder="Enter mobile number"
//           value={mobileNumber}
//           onChange={(e) => setMobileNumber(e.target.value)}
//         />
//         {otpSent && (
//           <input
//             type="text"
//             placeholder="Enter OTP"
//             value={otp}
//             onChange={(e) => setOtp(e.target.value)}
//           />
//         )}
//         {isOtpVerified && (
//           <input
//             type="text"
//             placeholder="Enter your username"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//           />
//         )}
//         {!otpSent ? (
//           <button onClick={requestOtp}>Send OTP</button>
//         ) : isOtpVerified ? (
//           <button onClick={submitUsername}>Submit Username</button>
//         ) : (
//           <button onClick={verifyOtp}>Verify OTP</button>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Login;

import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { FaEdit, FaCheck } from 'react-icons/fa';

function Login() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [username, setUsername] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const requestOtp = async () => {
    try {
      await axios.post('/api/auth/requestOtp', { mobileNumber });
      setOtpSent(true);
    } catch (error) {
      console.error('Error requesting OTP:', error.response.data);
    }
  };

  const verifyOtp = async () => {
    try {
      const response = await axios.post('/api/auth/verifyOtp', { mobileNumber, otp });
      if (response.data.token) {
        setIsOtpVerified(true);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error.response);
    }
  };

  const submitUsername = async () => {
    try {
      const response = await axios.post('/api/auth/submitUsername', { mobileNumber, username });
      if (response.data.token) {
        // Redirect user to search page or perform other actions upon successful submission
        localStorage.setItem('userToken', response.data.token);
        console.log("Redirecting to search...");
      // history.push('/search');
      window.location.href = '/search';
      }
    } catch (error) {
      console.error('Error submitting username:', error.response.data);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h1 className="title">Enter your phone number</h1>
        <p className="description">
          We'll send you a verification code.
          <br />
          Message and data rates may apply.
        </p>
        <div className="phone-input-container">
          <input
            className="phone-input"
            type="text"
            placeholder="Enter mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            disabled={otpSent}
          />
          {otpSent && !isOtpVerified && (
            <button className="edit-icon" onClick={() => setOtpSent(false)}>
              <FaEdit />
            </button>
          )}
          {isOtpVerified && (
            <span className="verified-icon">
              <FaCheck />
            </span>
          )}
        </div>
        {otpSent && !isOtpVerified && (
          <input
            className={`otp-input ${!isOtpVerified ? 'error' : ''}`}
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        )}
        {isOtpVerified && (
          <input
            className="username-input"
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        )}
        {!otpSent ? (
          <button className="send-otp-button" onClick={requestOtp}>
            Send OTP
          </button>
        ) : isOtpVerified ? (
          <button className="start-suggesting-button" onClick={submitUsername}>
            Start Suggesting
          </button>
        ) : (
          <button className="verify-otp-button" onClick={verifyOtp}>
            Verify OTP
          </button>
        )}
      </div>
    </div>
  );
}

export default Login;
