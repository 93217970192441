import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';

// Styled Components for Player UI
const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  color: #FFFFFF;
  width: 100%; // Ensure the player container takes full width
`;

const NowPlaying = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const CoverImage = styled.div`
  width: 256px;
  height: 256px;
  background-size: cover;
  background-image: url(${props => props.url});
  border-radius: 1rem;
`;

const TrackInfo = styled.div`
  margin-top: 1rem;
`;

const TrackName = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

const TrackArtist = styled.p`
  font-size: 1rem;
  color: #999999;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%; // Ensure the progress bar container takes full width
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 0.25rem; // Increase the height for better visibility
  background-color: rgba(56, 224, 120, 0.2);
  position: relative;
  border-radius: 0.25rem; // Increase the border-radius for a smoother look

  &::before {
    content: '';
    display: block;
    width: ${props => props.progress}%;
    height: 100%;
    background-color: #38E078;
    border-radius: 0.25rem;
  }

`;

const TimeDisplay = styled.p`
  font-size: 0.75rem;
  color: #999999;
`;

const ControlButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 0.8rem;
`;

const ControlButton = styled.button`
  background-color: ${props => (props.active ? 'transparent' : 'transparent')};
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  color: ${props => (props.active ? '#FFFFFF' : '#38E078')};
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #FFFFFF;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
    color: #38E078;
  }
`;

const ControlButtonPlay = styled.button`
  background-color: ${props => (props.active ? '#38E078' : 'transparent')};
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  color: ${props => (props.active ? '#FFFFFF' : '#38E078')};
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #FFFFFF;
  }

  svg {
    width: 4rem;
    height: 4rem;
    color: black;
  }
`;

const VolumeControlContainer = styled.div`
  display: flex;
  flex-direction: row; // Align items in a row
  justify-content: space-between; // Place items on far corners
  align-items: center; // Align items vertically in the center
  margin-top: 1rem;
  width: 100%; // Ensure the container takes full width

  @media (max-width: 768px) {
    display: none; // Hide the container on mobile devices
  }
`;

const VolumeControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem; // Adjust gap to be smaller
`;

const VolumeLabel = styled.p`
  font-size: 0.875rem; // Reduce font size if needed
  color: #FFFFFF;
  margin-right: 1rem; // Add some space between the label and the bar
  margin-left: 1rem;
`;

const VolumeBar = styled.div`
  flex: 0.8;
  height: 0.25rem; // Reduce the height for a smaller volume bar
  background-color: #3D3D3D;
  position: relative;
  border-radius: 0.125rem; // Adjust border radius if needed
  margin-left: 1rem; // Add some space between the label and the bar

  &::before {
    content: '';
    display: block;
    width: ${props => props.volume}%;
    height: 100%;
    background-color: #38E078;
    border-radius: 0.125rem;
  }
`;

const MusicPlayer = ({ token, queue, venueId, tokenRefreshed, reloadParentPage, refreshToken }) => {
    const track = {
        name: "",
        album: { images: [{ url: "" }] },
        artists: [{ name: "" }]
    };

    const [player, setPlayer] = useState(undefined);
    const [currentTrack, setCurrentTrack] = useState(track);
    const [is_paused, setPaused] = useState(false);
    const [is_active, setActive] = useState(false);
    const [deviceId, setDeviceId] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [queueIndex, setQueueIndex] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [volume, setVolume] = useState(50);
    const [shouldReload, setShouldReload] = useState(false);
    const [shouldPlayerDisconnect, setShouldPlayerDisconnect] = useState(false);
    const [currentTrackTimeLeft, setCurrentTrackTimeLeft] = useState(0);
    const [currentTrackDuration, setCurrentTrackDuration] = useState(0);

    const playerRef = useRef(null);
    const queueRef = useRef(queue);
    const queueIndexRef = useRef(queueIndex);
    const deviceIdRef = useRef(deviceId);
    const venueIdRef = useRef(venueId);
    const tokenRef = useRef(token);
    const tokenRefreshedRef = useRef(tokenRefreshed);

    useEffect(() => { deviceIdRef.current = deviceId; }, [deviceId]);
    useEffect(() => { queueRef.current = queue; }, [queue]);
    useEffect(() => { queueIndexRef.current = queueIndex; }, [queueIndex]);
    useEffect(() => {
        console.log(" token refresh ref current refreshed: " + tokenRefreshed);
        tokenRefreshedRef.current = tokenRefreshed
    }, [tokenRefreshed]);

    useEffect(() => {
        console.log("use effect for shouldReload: " + tokenRefreshed);
        if (tokenRefreshed) {
            setShouldReload(true);
        }
    }, [tokenRefreshed]);

    useEffect(() => {
        console.log("first use effect tokenrefreshed: " + tokenRefreshed + "b" + tokenRefreshedRef.current);
        if (!token || playerRef.current || tokenRefreshed) return;

        console.log("first use effect");

        // if(!shouldReload) return;

        const script = document.createElement('script');
        script.src = 'https://sdk.scdn.co/spotify-player.js';
        script.async = true;
        document.body.appendChild(script);

        window.onSpotifyWebPlaybackSDKReady = () => {
            const newPlayer = new window.Spotify.Player({
                name: 'Web Playback SDK',
                getOAuthToken: cb => { cb(token); },
                volume: volume / 100
            });

            playerRef.current = newPlayer;
            setPlayer(newPlayer);

            newPlayer.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id);
                setDeviceId(device_id);
                setIsReady(true);
            });

            newPlayer.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
                setIsReady(false);
            });

            newPlayer.addListener('player_state_changed', _.debounce((state) => {
                if (!state) return;
                console.log("tokenRefreshed: " + tokenRefreshedRef.current);
                setCurrentTrack(state.track_window.current_track);
                setPaused(state.paused);
                updatePlayerStateBackend(venueIdRef.current, state.paused ? 'paused' : 'playing');
                setCurrentPosition(state.position);
                setCurrentTrackDuration(state.track_window.current_track.duration_ms);
                console.log('Player state changed:', state);

                newPlayer.getCurrentState().then(state => {
                    setActive(state !== null);
                });

                if (state.paused && state.position === 0 && state.track_window.previous_tracks.length > 0) {
                    handleNextTrack();
                }
            }, 500));

            setInterval(() => {
                setCurrentTrackTimeLeft(currentTrackDuration - currentPosition);
            }, 1000);

            newPlayer.setName("Mutefly").then(() => {
                console.log('Player name updated!');
            });

            newPlayer.addListener('initialization_error', ({ message }) => {
                console.error('Initialization error:', message);
            });

            newPlayer.addListener('authentication_error', ({ message }) => {
                console.error('Authentication error:', message);
            });

            newPlayer.addListener('account_error', ({ message }) => {
                console.error('Account error:', message);
            });

            newPlayer.addListener('playback_error', ({ message }) => {
                console.error('Playback error:', message);
            });

            newPlayer.connect().then(success => {
                if (success) {
                    console.log('The Web Playback SDK on Mutefly successfully connected to Spotify!');
                } else {
                    console.error('The Web Playback SDK could not connect to Spotify.');
                }
            });
        };

        return () => {
            console.log("tokenrefreshedref.current: " + tokenRefreshedRef);
            if (tokenRefreshed) {
                console.log("not disconnecting player");
            } else if (playerRef.current) {
                console.log("set Disconnecting Player true");
                setShouldPlayerDisconnect(true);
                playerRef.current.disconnect();
                //on refreshing token flow is coming here
            }
        };
    }, [token]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          // Update backend with the player status as "off"
          updatePlayerStateBackend(venueIdRef.current, 'off'); // Set the player status as "off"
          
          // Optionally prevent the user from closing the tab (modern browsers may not allow this)
          event.preventDefault();
          event.returnValue = ''; // Some browsers require this to show a message to the user
        };
      
        // Listen for the beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    // useEffect(() => {
    //     let timeoutId;
    //     if (shouldPlayerDisconnect && !tokenRefreshed) {
    //         console.log("Disconnecting Player");
    //         playerRef.current.disconnect();
    //     } else if(tokenRefreshed){
    //         // // Wait for the current track to finish
    //         const timeLeft = currentTrackDuration-currentPosition;
    //         console.log("currentPosition: "+currentPosition);
    //         console.log("current duration: "+currentTrackDuration);
    //         console.log("time left: "+timeLeft);
    //         timeoutId = setTimeout(() => {
    //             console.log("Reloading window after song finishes.");
    //             axios.post(`${process.env.REACT_APP_BACKEND_URL}/queue/remove/${venueIdRef.current}`, { index: 0 })
    //             .then(response => {
    //                 console.log('Song removed from queue:', response.data);
    //                 // Update the queue index after removing the song
    //             })
    //             .catch(error => {
    //                 console.error('Error removing song from queue:', error);
    //             });
    //             window.location.reload(); // Reload the window
    //         }, timeLeft); // Wait for the duration of the current track
    //     }

    //     return () => clearTimeout(timeoutId); // Cleanup the timeout
    // }, [shouldPlayerDisconnect, currentTrackTimeLeft]);


    useEffect(() => {
        if (isReady && deviceId && queue.length > 0) {
            playTrack(queue[0].uri);
        }
    }, [isReady, deviceId]);

    useEffect(() => {
        let interval = null;
        if (is_active && !is_paused) {
            interval = setInterval(() => {
                playerRef.current.getCurrentState().then(state => {
                    if (state) {
                        setCurrentPosition(state.position);
                    }
                });
            }, 1000); // Update every second
        } else if (is_paused) {
            clearInterval(interval);
            console.log("in ispaused state");
            // if(tokenRefreshedRef.current) {
            //     window.location.reload();
            // }
        }
        return () => clearInterval(interval);
    }, [is_active, is_paused]);

    const playTrack = (uri, position = 0) => {
        const deviceIdCurrent = deviceIdRef.current;
        if (!deviceIdCurrent) {
            console.error('No device ID available');
            return;
        }
        playerRef.current._options.getOAuthToken(accessToken => {
            fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceIdCurrent}`, {
                method: 'PUT',
                body: JSON.stringify({ uris: [uri], position_ms: position }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            }).then(() => {
                console.log('Track playing');
                updatePlayerStateBackend(venueIdRef.current, 'playing');
            }).catch(error => {
                console.error('Error playing track:', error);
            });
        });
    };

    const handlePlayPause = () => {
        if (playerRef.current && isReady) {
            playerRef.current.togglePlay().then(() => {
                console.log('Toggled playback!');
                playerRef.current.getCurrentState().then(state => {
                    setPaused(state?.paused);
                    updatePlayerStateBackend(venueIdRef.current, state?.paused ? 'paused' : 'playing');
                }).catch(error => {
                    console.error('Error getting current state:', error);
                });
            }).catch(error => {
                console.error('Error toggling playback:', error);
            });
        } else {
            console.error('Player is not initialized or not ready.');
        }
    };

    const handleNextTrack = useCallback(_.debounce(() => {
        const latestQueue = queueRef.current;

        if (tokenRefreshedRef.current) {
            console.log("Reloading window now.");
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/spotify/refresh_token/${venueId}`, { refresh_token: refreshToken })
                .then(response => {
                    console.log('refreshed access token: ', response.data.access_token);
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('refresh_token', response.data.response_token);
                })
                .catch(error => {
                    console.error('Error fetching refreshed token ', error);
                });

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/queue/remove/${venueIdRef.current}`, { index: 0 })
                .then(response => {
                    console.log('Song removed from queue:', response.data);
                    // Update the queue index after removing the song
                })
                .catch(error => {
                    console.error('Error removing song from queue:', error);
                });
            window.location.reload(); // Reload the window
            return;
        }

        if (queueIndexRef.current < latestQueue.length - 1) {
            const nextIndex = queueIndexRef.current + 1;
            setCurrentPosition(0);
            console.log("nextIndex:" + nextIndex);

            // if(shouldReload){
            //     // reloadParentPage();
            //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/queue/remove/${venueIdRef.current}`, { index: queueIndexRef.current })
            //     .then(response => {
            //         console.log('Song removed from queue:', response.data);
            //         // Update the queue index after removing the song
            //         setQueueIndex(nextIndex - 1);
            //         queueIndexRef.current = nextIndex - 1;
            //     })
            //     .catch(error => {
            //         console.error('Error removing song from queue:', error);
            //     });
            //     window.location.reload();
            //     return;
            // }

            playTrack(latestQueue[nextIndex].uri);

            // Remove the played song from the backend queue
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/queue/remove/${venueIdRef.current}`, { index: queueIndexRef.current })
                .then(response => {
                    console.log('Song removed from queue:', response.data);
                    // Update the queue index after removing the song
                    setQueueIndex(nextIndex - 1);
                    queueIndexRef.current = nextIndex - 1;
                })
                .catch(error => {
                    console.error('Error removing song from queue:', error);
                });
        }

        // setQueueIndex((prevQueueIndex) => {
        //     const latestQueue = queueRef.current; // Use the latest value of queue
        //     console.log("handle next track called");
        //     console.log("queue index:" + prevQueueIndex + " queue.length:" + latestQueue.length);
        //     if (prevQueueIndex < latestQueue.length - 1) {
        //       const nextIndex = prevQueueIndex + 1;
        //       console.log("nextIndex: " + nextIndex);
        //       setCurrentPosition(0);
        //       playTrack(latestQueue[nextIndex].uri);

        //       // Remove the played song from the backend queue
        //       axios.post(`${process.env.REACT_APP_BACKEND_URL}/queue/remove`, { index: prevQueueIndex })
        //         .then(response => {
        //           console.log('Song removed from queue:', response.data);
        //         })
        //         .catch(error => {
        //           console.error('Error removing song from queue:', error);
        //         });

        //       return nextIndex-1;
        //     }
        //     return prevQueueIndex; // Return the previous index if no update is needed
        //   });
    }, 500), []);

    const handlePreviousTrack = () => {
        if (queueIndex > 0) {
            setQueueIndex(queueIndex - 1);
            setCurrentPosition(0);
            playTrack(queue[queueIndex - 1].uri);
        }
    };

    const handleVolumeChange = (newVolume) => {
        setVolume(newVolume);
        if (playerRef.current) {
            playerRef.current.setVolume(newVolume / 100).then(() => {
                console.log(`Volume set to ${newVolume}%`);
            });
        }
    };

    const updatePlayerStateBackend = async (venueId, playerState) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/player/state/${venueId}`, {
                playerState: playerState,
            });
            console.log('Player state updated in backend:', response.data);
        } catch (error) {
            console.error('Error updating player state in backend:', error);
        }
    };

    return (
        <PlayerContainer>
            <NowPlaying>
                <CoverImage url={currentTrack.album.images[0]?.url} alt="Now Playing" />
                <TrackInfo>
                    <TrackName>{currentTrack.name}</TrackName>
                    <TrackArtist>{currentTrack.artists[0]?.name}</TrackArtist>
                </TrackInfo>
            </NowPlaying>
            <ProgressBarContainer>
                <TimeDisplay>{Math.floor(currentPosition / 60000)}:{Math.floor((currentPosition % 60000) / 1000).toString().padStart(2, '0')}</TimeDisplay>
                <ProgressBar progress={(currentPosition / currentTrack.duration_ms) * 100} />
                <TimeDisplay>{Math.floor(currentTrack.duration_ms / 60000)}:{Math.floor((currentTrack.duration_ms % 60000) / 1000).toString().padStart(2, '0')}</TimeDisplay>
            </ProgressBarContainer>
            <ControlButtons>
                <ControlButton onClick={handlePreviousTrack} active={isReady}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 256"><path d="M208,47.88V208.12a16,16,0,0,1-24.43,13.43L64,146.77V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0v69.23L183.57,34.45A15.95,15.95,0,0,1,208,47.88Z" /></svg>
                </ControlButton>
                <ControlButtonPlay onClick={handlePlayPause} active={isReady}>
                    {is_paused ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="24px" height="24px">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 5v14l11-7z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="24px" height="24px">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                        </svg>
                    )}
                </ControlButtonPlay>
                <ControlButton onClick={handleNextTrack} active={isReady}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 256">
                        <path d="M48 208.12V47.88a16 16 0 0124.43-13.43L192 109.23V40a8 8 0 0116 0v176a8 8 0 01-16 0v-69.23L72.43 221.55A15.95 15.95 0 0148 208.12z" />
                    </svg>
                </ControlButton>
            </ControlButtons>
            <VolumeControlContainer>
                <VolumeLabel>Volume</VolumeLabel>
                <VolumeBar volume={volume} />
            </VolumeControlContainer>
        </PlayerContainer>
    );
};

export default MusicPlayer;