import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import ReactGA from "react-ga4";
import SpotifyPlayer from '../utility/SpotifyPlayer'; // Import SpotifyPlayer

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PageContainer = styled.div`
  background-color: #121217;
  background-size: cover;
  background-position: center;
  height: 100vh;
`;

const StyledHeader = styled.header`
  background-color: #121217;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 40px;
  border-bottom: 1px solid rgba(229, 232, 235, 1);
  font-size: 16px;
  color: #9ea3b8;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #292b38;
  padding: 8px 16px;
  border-radius: 12px;
  color: #fff;
  font-family: "Be Vietnam Pro", sans-serif;
`;

const Logo = styled.img`
  width: 40px;
`;

const SearchIcon = styled.img`
  width: 24px;
`;

const ContentContainer = styled.main`
  width: 100%;
  max-width: 960px;
  margin-top: 0;
  padding: 16px;
`;

const TitleContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
`;

const Breadcrumb = styled.div`
  color: #9ea3b8;
  display: flex;
  gap: 8px;
`;

const PageTitle = styled.h2`
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.8px;
  margin: 0px;
`;

const QueueSection = styled.section`
  margin-top: 24px;
`;

const SongItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
`;

const SongDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SongInfo = styled.div``;

const SongTitle = styled.h3`
  color: #fff;
  font: 500 16px "Be Vietnam Pro", sans-serif;
`;

const SongDuration = styled.p`
  color: #9ea3b8;
  font: 400 14px "Be Vietnam Pro", sans-serif;
`;

const SmallImage = styled.img`
  width: 100px;
  height: 56px;
  object-fit: cover;
  border-radius: 16px;
`;

const Pagination = styled.nav`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  font-size: 14px;
  color: #fff;
`;

const PageNumber = styled.button`
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 700;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #292b38;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const SecondaryLogo = styled.img`
  width: 18px;
`;

const FooterImage = styled.img`
  width: 130px;
  margin: 12px auto 0;
  display: block;
`;

const SongList = ({ queue }) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = queue.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(queue.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return Array.isArray(queue) && queue.length > 0 ? (
    <>
      {currentItems.map((song, index) => (
        <SongItem key={index}>
          <SongDetails>
            <SmallImage src={song.album.images[0].url} alt="Song Thumbnail" />
            <SongInfo>
              <SongTitle>{song.name} - {song.artists[0].name}</SongTitle>
              <SongDuration>{Math.floor((song.duration_ms/1000/60) << 0)}:{Math.floor((song.duration_ms/1000) % 60)}</SongDuration>
            </SongInfo>
          </SongDetails>
        </SongItem>
      ))}
      <Pagination>
        {pageNumbers.map((number) => (
          <PageNumber key={number} onClick={() => setCurrentPage(number)}>
            {number}
          </PageNumber>
        ))}
      </Pagination>
    </>
  ) : (
    <li style={{ color: 'wheat' }}>No songs in the queue</li>
  );
};

function QueueListPage() {
  const [songQueueData, setsongQueueData] = useState([]);
  const [token, setToken] = useState('');
  const [lastPlayedSong, setLastPlayedSong] = useState([]);
  const [songRecommendation, setSongRecommendation] = useState([]);
  var lastPlayed;
  var songRec;

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        if (accessToken) {
          console.log("Access token from local storage fetched");
          setToken(accessToken);
        } else if (refreshToken) {
          console.log("Fetching access token from refresh token endpoint");
          const response = await axios.post(`${backendUrl}/refresh_token`, { refresh_token: refreshToken });
          setToken(response.data.access_token);
          localStorage.setItem('access_token', response.data.access_token);
        } else {
          window.location.href = '/login'; // Redirect to login page
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        window.location.href = '/login'; // Redirect to login page
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    const handleSendToQueue = async (songRec) => {
      if (!songRec) return;
      const timestamp = new Date().toISOString();
      const response = await fetch(`${backendUrl}/queue/add`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ song: [songRec] }),
      });
      if (response.status === 200) {
        console.log("Song added! Keep the vibe going.");
      } else {
        console.log("Error adding song to queue.");
      }
    };

    const fetchRecommendation = async (lastPlayed) => {
      const seed_artists = lastPlayed.artists[0].id;
      const seed_track = lastPlayed.id;
      try {
        const response = await axios.get(`${backendUrl}/api/spotify/recommendation?seed_artists=${seed_artists}&seed_tracks=${seed_track}`);
        handleSendToQueue(response.data[0]);
      } catch (error) {
        console.error('Error fetching recommendation:', error);
      }
    };

    const fetchsongQueueData = () => {
      axios.get(`${backendUrl}/queue`)
        .then(response => {
          console.log("Fetching response of queue data");
          setsongQueueData(response.data);
          if (response.data.length === 1) {
            fetchRecommendation(response.data[0]);
          }
        })
        .catch(error => {
          console.error('Error fetching song queue:', error);
        });
    };

    fetchsongQueueData();
    const intervalId = setInterval(fetchsongQueueData, 40000);
    return () => clearInterval(intervalId);
  }, [token]);

  return (
    <PageContainer>
      <TitleContainer>
        <Breadcrumb>
          <div>Venue</div>
          <div>/</div>
          <div>Scan The Vibe Cafe</div>
        </Breadcrumb>
      </TitleContainer>
      <ContentContainer>
        <PageTitle>Song Queue</PageTitle>
        <QueueSection>
          <SongList queue={songQueueData} />
        </QueueSection>
        <SpotifyPlayer token={token} queue={songQueueData} /> {/* Add SpotifyPlayer */}
      </ContentContainer>
    </PageContainer>
  );
}

export default QueueListPage;
