import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import styled from 'styled-components';
import errorPage from '../../assets/error_404.png';

const ErrorImg = styled.div`
    background-image: url(${errorPage});
    background-size: cover;
    background-position: center;
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:-1;
`
const ErrorPage = () => {
    const history = useHistory();
    const [startY, setStartY] = useState(0);
    const [endY, setEndY] = useState(0);

    useEffect(() => {
        const handleTouchStart = (e) => {
            setStartY(e.touches[0].clientY);
        };

        const handleTouchEnd = (e) => {
            setEndY(e.changedTouches[0].clientY);
        };

        const handleTouchMove = () => {
            if (endY - startY > 50) { // Adjust the threshold for pull-down
                history.push('/search');
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startY, endY, history]);

    return <ErrorImg />;
};


export default ErrorPage;