import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import ReactGA from "react-ga4";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const FormField = ({ label, value, type = 'text', placeholder, onChange, onBlur }) => (
  <FieldContainer>
    <Label>{label}</Label>
    <Input type={type} value={value} placeholder={placeholder} onChange={onChange} onBlur={onBlur} required />
  </FieldContainer>
);

const birdFlying = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const Bird = styled.div`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background: url('/path-to-bird-image.png') no-repeat center center;
  background-size: contain;
  animation: ${birdFlying} 4s infinite;
`;

const FeedbackForm = ({ venueId }) => {
  useEffect(() => {
    ReactGA.event('cu_view', {
      category: 'User',
      action: 'cu_view',
      venueId: venueId,
    });
  }, [venueId]);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [feedback, setFeedback] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showBird, setShowBird] = useState(false);

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  const handleFeedbackChange = (e) => {
    const value = e.target.value;
    const charCount = value.replace(/\s/g, '').length;
    if (charCount <= 200) {
      setFeedback(value);
      setCharCount(charCount);
    } else {
      setShowPopup(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting feedback:', { name, phone, feedback }); // Added for logging

    ReactGA.event('cu_submit_tap', {
      category: 'User',
      action: "cu_submit_tap",
      venueId: venueId,
      input_values: JSON.stringify({ name, phone, feedback })

    });

    try {
      const response = await axios.post(
        `${backendUrl}/api/feedback/submit/`,
        { name, phone, feedback },
      );
      console.log('Server response:', response.data); // Added for logging
      alert('Feedback submitted successfully');
      setShowBird(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error submitting feedback', error);
      alert('Error submitting feedback');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <PageContainer>
      <Header><h6>Help us elevate your experience!</h6></Header>
      <Container onSubmit={handleSubmit} isBlurred={showPopup}>
        <FormField 
          label='Name' 
          value={name} 
          placeholder='Wolverine' 
          onChange={(e) => setName(e.target.value)} 
          onBlur={() => 
            ReactGA.event('cu_name_entry_tap', {
            category: 'User',
            action: "cu_name_entry_tap",
            venueId: venueId,
            input_values: JSON.stringify({ name })
          })}
        />
        <FormField 
          label='Phone' 
          value={phone} 
          type="tel"
          placeholder='9999210999' 
          onChange={handlePhoneChange} 
          onBlur={() => 
            ReactGA.event('cu_phone_entry_tap', {
            category: 'User',
            action: "cu_phone_entry_tap",
            venueId: venueId,
            input_values: JSON.stringify({ phone })
          })}
        />
        {/* <FormField 
          label="Anything you'd like us to know?" 
          value={feedback} 
          type="text"
          placeholder="We're eager to hear from you"
          onChange={handleFeedbackChange} 
          onBlur={() => 
            ReactGA.event('cu_note_entry_tap', {
                category: 'User',
                action: "cu_note_entry_tap",
                venueId: venueId,
                input_values: JSON.stringify({ feedback },{feedback})
          })}
        /> */}
        <FieldContainer>
          <Label>Anything you'd like us to know?</Label>
          <Textarea 
            value={feedback} 
            placeholder="We're eager to hear from you" 
            onChange={handleFeedbackChange} 
            onBlur={() => 
              ReactGA.event('cu_note_entry_tap', {
              category: 'User',
              action: "cu_note_entry_tap",
              venueId: venueId,
              input_values: JSON.stringify({ feedback },{feedback})
            })}
          />
          <CharCounter>{charCount}/200</CharCounter>
        </FieldContainer>
        <SubmitButton type='submit'>Submit</SubmitButton>
      </Container>
      {showPopup && (
        <Popup>
          <PopupContent>
            We think this space isn't enough for your thoughts towards us. Let us connect at <EmailLink href="mailto:scanthevibe@gmail.com">scanthevibe@gmail.com</EmailLink>.
            <NoThanksButton onClick={handleClosePopup}><u>No Thanks</u></NoThanksButton>
          </PopupContent>
        </Popup>
      )}
      {showBird && <Bird />}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  background-color: #141414;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
`;

const Container = styled.form`
  background-color: rgba(26, 26, 26, 0.6);
  backdrop-filter: blur(25px); /* Frost effect */
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  line-height: 150%;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  align-items: center;
  gap: 20px;
  border-radius: 8px; /* Adjust border-radius as per the requirement */
  filter: ${props => props.isBlurred ? 'blur(4px)' : 'none'};
  @media (min-width: 768px) {
    font-size: 18px;
  }
  /* To adjust the frost effect, change the values of blur and background-color opacity */
  /* backdrop-filter: blur(15px); // Increase blur */
  /* background-color: rgba(26, 26, 26, 0.7); // Increase opacity */
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const Header = styled.h1`
  width: 100%;
  letter-spacing: -0.03em;
  font: bold 32px/1.2 ;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
  
  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const Subheader = styled.p`
  font-weight: bold;
  color: #ffffff;
  letter-spacing: -0.02em;
  width: 100%;
  font: bold 18px/1.5 ;
  margin-bottom: 20px;
  text-align: left;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const Label = styled.label`
  font-weight: 500;
  width: 100%;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(61, 61, 61, 1);
  background-color: transparent;
  color: #999;
  padding: 10px 0px;
  padding-left: 10px;
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
  &::placeholder {
    color: #666;
    font-weight: 400;
  }
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(61, 61, 61, 1);
  background-color: transparent;
  color: #999;
  padding: 10px 0px;
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
  min-height: 100px;
  resize: vertical;
  &::placeholder {
    padding-right: 10px;
    padding-left: 10px;
    color: #666;
    font-weight: 400
    }
    @media (min-width: 768px) {
        font-size: 18px;
    }
`;

const CharCounter = styled.div`
    font-size: 14px;
    color: #666;
    text-align: right;
    margin-top: 5px;
`;

const SubmitButton = styled.button`
    border-radius: 40px;
    background: linear-gradient(to bottom right, #FFBA33, #664A14); // Gradient from #FFC629 to black
    display: flex;
    left: 50%;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: bolder;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Slightly stronger shadow for depth
    transition: background-color 0.3s, opacity 0.3s;

    &:active {
        background: linear-gradient(90deg, rgba(69, 160, 73, 1)); // Darker gradient when tapped or clicked
        opacity: 0.3; // Slightly less opaque to simulate a press effect
    }

    @media (min-width: 768px) {
        font-size: 20px;
    }
`;

const Popup = styled.div`
    position: fixed;
    width: 60%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    z-index: 1141414;
`;

const PopupContent = styled.p`
    text-align: center;
    color: #141414;
`;

const EmailLink = styled.a`
    font-weight: bold;
    color: #141414;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const NoThanksButton = styled.button`
    margin-top: 10px;
    font-weight: 500;
    color: #141414;
    background: none;
    border: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export default FeedbackForm;
