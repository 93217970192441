import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Link, useHistory } from "react-router-dom";
import ReactGa4 from 'react-ga4';
import styled, { createGlobalStyle } from 'styled-components';
import MyComponent3 from './components/SearchPage/SearchPage3';
import Login from './components/auth/Login';
import ErrorPage from './components/ErrorPage/ErrorPage';
import SongList from './components/venuePage/SongList';
import soundBarImg from "./assets/soundbar-img.png";
import headerNew from './assets/header-new.png';
import headerNew1 from './assets/header-new-1.png';
import headerNew2 from './assets/header-new-2.png';
import muteflyHeader from './assets/mutefly-header.png';
import QueueListPage from './components/venuePage/QueueList';
import PlaylistPage from './components/PlaylistPage/PlaylistPage';
import { MenuProvider, useMenu } from './context/MenuContext';
import menuIconOld from './assets/menu-icon.png';
import menuIcon from './assets/menu-icon-new.png';
import menuIcon1 from './assets/new-menu-icon-1.png';
import menuIcon2 from './assets/new-menu-icon-2.png';
import SpotifySearchComponent from './components/SearchPage/SpotifySearch';
import SpotifyLogin from './components/auth/SpotifyLogin';
import SpotifyPlayer from './components/utility/SpotifyPlayer';
import VenuePlayer from './components/venuePage/VenuePlayer';
import VenueLogin from './components/auth/VenueLogin';
import AboutUs from './components/ContactPage/AboutUs';
import FeedbackForm from './components/ContactPage/ContactUs'; // Import FeedbackForm from Frontend


// Initialize Google Analytics
ReactGa4.initialize('G-NQHQBJXK5S');

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'League Spartan', sans-serif;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
`;

const Overlay = styled.div`
  display: ${(props) => (props.$show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background: #141414;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) => (props.$show ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
`;

const MenuButton = styled.button`
  background: url(${menuIcon}) no-repeat center center;
  background-size: contain;
  border: none;
  width: 8%;
  height: 2%;
  cursor: pointer;
  position: fixed;
`;

const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 16px;
  &:hover {
    background-color: #292929;
  }
`;

const SideMenuHeader = styled.div`
  background: #141414;
  color: #fff;
  padding: 33px;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #444;
`;

const Header = styled.header`
  top: 0;
  left: 0;
  right: 0;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 30px;
  z-index: 1000;
  width: 100% // Adjust width based on menu state
  transition: transform 0.3s ease-in-out;// Smooth transition for the movement
  position: fixed;
`

const SoundBar = ({venueId}) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {

    // Log GA event directly
    ReactGa4.event({
      category: 'Navigation',
      action: 'logo_tap',
      label: location.pathname, // Include the location from where it is being tapped
      customParams: {
        venueId: venueId,
      },
    });

    const searchPath = `/search?venueId=${venueId}`;
    if (location.pathname === '/search') {
      window.location.reload();
    } else {
      history.push(searchPath);
    }
  };

  return (
    <StyledSoundBar onClick={handleClick} />
  );
};

const StyledSoundBar = styled.div`
  background: url(${muteflyHeader}) no-repeat center center;
  // padding-left: ${(props) => (props.$show ? "250px" : "0")};
  background-size: contain;
  border: none;
  margin-left: 15%;
  width: 70%;
  height: 40px;
  cursor: pointer;
  left: 0px;
`;

const AppContent = ({ token, venueId }) => {
  return (
    <Switch>
      <Route path="/search">
        <SpotifySearchComponent venueId={venueId} />
      </Route>
      <Route path="/queue">
        <QueueListPage venueId={venueId} />
      </Route>
      <Route path="/venue">
        <VenuePlayer venueId={venueId} />
      </Route>
      <Route path="/playlist">
        <PlaylistPage venueId={venueId} />
      </Route>
      <Route path="/aboutus">
        <AboutUs venueId={venueId} />
      </Route>
      <Route path="/error">
        <ErrorPage />
      </Route>
      <Route path="/player">
        <SpotifyPlayer venueId={venueId} />
        {/* {token ? <SpotifyPlayer token={token} /> : <Redirect to="/login" />} */}
      </Route>
      {/* <Route path="/login">
        {token? <Redirect to={`/venue?venueId=${venueId}`}/> : <SpotifyLogin venueId={venueId}/>}
      </Route> */}
      <Route path="/venuelogin">
        {token? <Redirect to={`/venue?venueId=${venueId}`}/> : <VenueLogin venueId={venueId}/>}
        {/* <VenueLogin venueId={venueId} /> */}
      </Route>
      <Route path="/submitFeedback">
        <FeedbackForm venueId={venueId}/>
      </Route>
      <Redirect from="/" to="/search" />
    </Switch>
  );
};

const App = () => {
  const [venueId, setVenueId] = useState('default');
  const [token, setToken] = useState('');
  useEffect(() => {
    console.log('App component rendered or re-rendered');
    const params = new URLSearchParams(window.location.search);
    const newVenueId = params.get('venueId');
    setToken(params.get('access_token'));

    if (newVenueId !== venueId) {
      setVenueId(newVenueId);
    }
    // console.log('params: '+params);
    // const accessToken = params.get('access_token');
    // const refreshToken = params.get('refresh_token');
    // console.log('accessToken: '+accessToken);
    // if (accessToken || refreshToken) {
    //   localStorage.setItem('access_token', accessToken);
    //   localStorage.setItem('refresh_token', refreshToken);
    // } else {
    //   // Handle error
    //   console.error('Error: No access or refresh token found');
    // }
    // if (accessToken) {
    //   setToken(accessToken);
    //   // window.history.replaceState({}, document.title, "/player");
    // }
  }, [venueId]);
  return (
    <>
      <MenuProvider venueId={venueId}>
        <Router>
          <GlobalStyle />
          <ConditionalHeaderAndMenu venueId={venueId} />
          <AppContent token={token} venueId={venueId} />
        </Router>
      </MenuProvider>
    </>
  );
};

const ConditionalHeaderAndMenu = ({ venueId }) => {
  const location = useLocation();
  const { menuOpen, toggleMenu } = useMenu();

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpen, venueId]);

  // Define paths where you want to hide the SideMenu and Header
  const pathsToHide = ['/queue', '/player', '/login', '/venue', '/venuelogin'];

  if (pathsToHide.includes(location.pathname)) {
    return null; // Return null to render nothing
  }

  return (
    <>
      {/* <SideMenu $show={menuOpen}>
        <SideMenuHeader></SideMenuHeader>
        <MenuItem to="/search" onClick={toggleMenu}>Search</MenuItem>
        <MenuItem to="/playlist" onClick={toggleMenu}>Live Playlist</MenuItem>
      </SideMenu> */}
      <HeaderComponent $menuOpen={menuOpen} venueId={venueId}>
        <MenuButton $show={menuOpen} onClick={toggleMenu} />
        <SoundBar />
      </HeaderComponent>
    </>
  );
};

const HeaderComponent = ({ venueId }) => {
  const { menuOpen, toggleMenu } = useMenu();
  const location = useLocation();

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpen]);

  const handleHomeClick = (e) => {
    const homePath = `/search?venueId=${venueId}`;
    if (location.pathname + location.search === homePath) {
      e.preventDefault();
      window.location.reload();
    } else {
      toggleMenu();
    }
  };

  return (
    <>
      <SideMenu $show={menuOpen}>
        <SideMenuHeader></SideMenuHeader>
        <MenuItem to={`/search?venueId=${venueId}`} onClick={handleHomeClick}>Home</MenuItem>
        <MenuItem to={`/playlist?venueId=${venueId}`} onClick={toggleMenu}>Live Playlist</MenuItem>
        <MenuItem to={`/submitFeedback`} onClick={toggleMenu}>Contact Us</MenuItem>
        <MenuItem to={`/aboutus`} onClick={toggleMenu}>About Us</MenuItem>
      </SideMenu>
      <Overlay $show={menuOpen} onClick={toggleMenu} />
      <Header $menuOpen={menuOpen}>
        <MenuButton onClick={toggleMenu} />
        <SoundBar venueId={venueId} />
      </Header>
    </>
  );
};

export default App;
