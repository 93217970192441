// import * as React from "react";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import ReactGA from "react-ga4";
import menuIcon from "../../assets/menu-icon.png";
import {Link, useHistory } from "react-router-dom";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AppWrapper = styled.div`
  background-color: #141414;
  background-size: cover;
  background-position: center;
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  height:100vh;
  overflow: hidden;
  margin: 0 auto;
`;

// const SoundBar = styled.div`
//   background: url(${soundBarImg}) no-repeat center center;
//   background-size: cover;
//   border: none;
//   margin-left: 15%;
//   width: 70%;
//   height: 40px;
//   cursor: pointer;
//   left: 0px;
// `;

const MenuButton = styled.button`
  background: url(${menuIcon}) no-repeat center center;
  background-size: cover;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: fixed;
`;

const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 16px;
  &:hover {
    background-color: #292929;
  }
`;

const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 5%;
  width: 250px;
  height: 100%;
  background: #141414;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) =>
    props.$show ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  z-index: 999;
`;

const Header = styled.header`
  top: 0;
  left: ${(props) =>
    props.$menuOpen ? "250px" : "0"}; // Adjust position based on menu state
  right: 0;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 30px;
  z-index: 1000;
  width: 100% // Adjust width based on menu state
  transition: transform 0.3s ease-in-out;// Smooth transition for the movement
`;

const PageContainer = styled.div`
  background-color: #141414;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  margin-top: 64px;
`;

const StyledHeader = styled.header`
  background-color: #121217;
  background-size: cover;
  backgroun-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 40px;
  border-bottom: 1px solid rgba(229, 232, 235, 1);
  font-size: 16px;
  color: #9ea3b8;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #292b38;
  padding: 8px 16px;
  border-radius: 12px;
  color: #fff;
`;

const Logo = styled.img`
  width: 40px;
`;

const SearchIcon = styled.img`
  width: 24px;
`;

const ContentContainer = styled.main`
  width: 100%;
  max-width: 960px;
  margin-top: 0;
  padding: 16px;
  box-sizing: border-box;
`;

const TitleContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
`;

const Breadcrumb = styled.div`
  color: #9ea3b8;
  display: flex;
  gap: 8px;
`;

const PageTitle = styled.h2`
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.8px;
  margin: 0px;
`;

const QueueSection = styled.section`
  margin-top: 24px;
  width: 100%;
`;

const SongItem = styled.div`
  display: flex;
  justify-content: space-between;
  // background-color: #292b38;
  border-radius: 8px;
  width: 100%;
`;

const SongDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SongInfo = styled.div`
//   display: flex;
//   flex-direction: column;
`;

const SongTitle = styled.h3`
  color: #fff;
  font: 500 16px;
`;

const SongDuration = styled.p`
  color: #9ea3b8;
  font: 400 14px;
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f00;
  padding: 10px 16px;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.21px;
  max-height: 40px;
  margin-right: 20px;
  margin-top: 15px;
`;

const PlayIcon = styled.img`
  width: 20px;
`;

const SmallImage = styled.img`
width: 56px;
height: 56px;
object-fit: cover;
border-radius: 16px;
`;

const Pagination = styled.nav`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  font-size: 14px;
  color: #fff;
`;

const PageNumber = styled.button`
  font-weight: 700;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #292b38;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const SecondaryLogo = styled.img`
  width: 18px;
`;

const FooterImage = styled.img`
  width: 130px;
  margin: 12px auto 0;
  display: block;
`;

const SongList = ({ venueId }) => {

  useEffect(() => {
    // ReactGA4.pageview(window.location.pathname + window.location.search);
    ReactGA.event('pp_view',
      { 
        category: "User",
        action: "pp_view",
        label: venueId,
    });

     // Log scroll event
     const handleScroll = () => {
      const scrollDepth = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight) * 100;
      ReactGA.event( 'pp_scroll',{
        category: "User",
        action: "pp_scroll",
        label: venueId,
        scroll_depth: `Scroll Depth: ${scrollDepth.toFixed(2)}%`,
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [venueId]);

  const [currentPage, setCurrentPage] = useState(1);

  const handleSongTap = (song) => {
    const songDetails = formatSongDetails(song);
    ReactGA.event( 'pp_song_tap',{
      category: "User",
      action: "pp_song_tap",
      label: venueId,
      song_details: formatSongDetails(songDetails),

    });
    // Implement song tap functionality
  };

  const formatSongDetails = (song) => {
    // const allArtists = song.artists.map(artist => artist.name).join(", ");
    return JSON.stringify({
      song_id: song.id,
      song_name: song.name,
      // song_artists: allArtists,
      // song_album: song.album.name,
    });
  };

  const [songQueueData, setsongQueueData] = useState(() => {
    const storedQueueData = localStorage.getItem('songQueueData');
    return storedQueueData ? JSON.parse(storedQueueData) : [];
  });
  
  const [fetchedIds, setFetchedIds] = useState(() => {
    const storedIds = localStorage.getItem('fetchedIds');
    return storedIds ? JSON.parse(storedIds) : [];
  });

  const itemsPerPage = 8;

  //NOTE: change this line for environment change
  const fetchsongQueueData = () => { 
    // axios.get('http://localhost:5000/queue')
    axios.get(`${backendUrl}/queue/${venueId}`)
      .then(response => {
        setsongQueueData(response.data);
      })
      .catch(error => {
        console.error('Error fetching song queue:', error);
      });
  };

  useEffect(() => {
    // Fetch song queue initially
    fetchsongQueueData();

    // Poll for song queue every 10 seconds
    const intervalId = setInterval(fetchsongQueueData, 10000*4);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('fetchedIds', JSON.stringify(fetchedIds));
  }, [fetchedIds]);

  useEffect(() => {
    localStorage.setItem('songQueueData', JSON.stringify(songQueueData));
  }, [songQueueData]);

  const refreshQueue = () => {
    fetchsongQueueData();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClick = (videoId) => {
    window.open('https://www.youtube.com/watch?v='+videoId,'_blank','noopener,noreferrer');
  };
  
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = songQueueData.slice(indexOfFirstItem,indexOfLastItem);

  // const pageNumbers=[];
  // for(let i=1; i<=Math.ceil(songQueueData.length/itemsPerPage); i++){
  //   pageNumbers.push(i);
  // }

  return Array.isArray(songQueueData) && songQueueData.length > 0 ? (
    <>
    {songQueueData.map((song) => (
      <SongItem key={song.id} onClick={() => handleSongTap(song)}>
        <SongDetails>
          <SmallImage src={song.album.images[0].url} alt="Video Thumbnail"/>
          <SongInfo>
            <SongTitle>{song.name}</SongTitle>
            <SongDuration>{song.artists[0].name}</SongDuration>
          </SongInfo>
        </SongDetails>
        {/* <PlayButton onClick={() => handleClick(song.id.videoId)}>
          <PlayIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8ea19aa913e33e9ec317c707adcc5d83de3d1dea97289fe0231dc27409fccb3?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Play" loading="lazy" />
          Play
        </PlayButton> */}
      </SongItem>
    ))}
    {/* <Pagination>
        {pageNumbers.map((number) => (
          <PageNumber key={number} onClick={() => handlePageChange(number)}>
            {number}
          </PageNumber>
        ))}
    </Pagination> */}
    </>
  ) : (
    <li style={{color:'wheat'}}>No songs in the queue</li>
  ) 
  
}

const PlaylistPage = ({ venueId }) => {

  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  const [venueName, setVenueName] = useState('');

  useEffect(() => {
    const fetchVenueName = () => {
      axios.get(`${backendUrl}/api/venue/name/${venueId}`)
        .then(response => {
          setVenueName(response.data.venueName);
        })
        .catch(error => {
          console.error('Error fetching venue name:', error);
        });
    };

    if (venueId) {
      fetchVenueName();
    }
  }, [venueId]);

  // const {venueName} = axios.get(`${backendUrl}/api/venue/name/${venueId}`);

  const handleVenueNameTap = () => {
    ReactGA.event('pp_venue_name_tap',
      { 
        category: "User",
        action: "pp_venue_name_tap",
        label: venueId,
    });
    // Implement venue name tap functionality
  };

  return (
    <AppWrapper>
        {/* <SideMenu $show={menuOpen}>
        <MenuItem to="/search">Search</MenuItem>
        <MenuItem to="/playlist">Queue</MenuItem>
      </SideMenu>
      <Header $menuOpen={menuOpen}>
        <MenuButton onClick={toggleMenu} />
      </Header> */}


        <PageContainer $menuOpen={menuOpen}> 
    {/* <StyledHeader>
        <NavBar>
          <SearchContainer>
            <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/f36fef528f91c54f065197f159904d7674976b57b3cb0940a5d1fdbb70d76a6c?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Logo" loading="lazy" />
          </SearchContainer>
          <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/84ae2031613417c1aa84c0b715970a00b15efc187b1e7daddd56d1294733d7c5?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Secondary Logo" loading="lazy" />
        </NavBar>
      </StyledHeader> */}
      <TitleContainer>
        <Breadcrumb onClick={handleVenueNameTap}>
          <div>Vibing at:</div>
          <div>{venueName}</div>
        </Breadcrumb>
      </TitleContainer>
      <ContentContainer>
        <PageTitle>Live Playlist</PageTitle>
        <QueueSection>
          <SongList venueId={venueId}/>
        </QueueSection>
      </ContentContainer>
      {/* <FooterImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/e00fbd34720eabcafd78b6e0491cbd658699a3375fa74dc9f1f4a4f969f102cf?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Footer" loading="lazy" /> */}
    
    </PageContainer>
    </AppWrapper>
  );
}

export default PlaylistPage;
