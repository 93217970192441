import React, { useState, useEffect } from "react";
import styled from "styled-components";
import menuIcon from "../../assets/menu-icon.png";
import soundBarImg from "../../assets/soundbar-img.png";
import uiBackgroundImg from "../../assets/uibackground.jpg";
import { FaPlus, FaCheck } from "react-icons/fa";
import ReactGA from "react-ga4";
import he from "he";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link, useHistory } from "react-router-dom";
import { useMenu } from '../../context/MenuContext';

// active page generated using chatgpt and galileo-figma-builder code

// const SearchBarPersist = () => {
//   const [isFixed, setIsFixed] =  useState(false);
//   useEffect(()=>{
//     const handleScroll = () => {
//       if(window.scrollY >= 30) {
//         setIsFixed(true);
//       } else {
//         setIsFixed(false);
//       }
//     }

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <`${isFixed? SearchBar : SearchBarFixed}`>
//   )
// }

const recentlyPlayedData = [
  {
    "kind": "youtube#searchResult",
    "etag": "eE6glAnU8KNidmRZNpG8HJFuxOQ",
    "id": {
        "kind": "youtube#video",
        "videoId": "j2zxCw3voU8"
    },
    "snippet": {
        "publishedAt": "2024-03-29T03:38:04Z",
        "channelId": "UCtGbExCzlwmsyWKpxLnyEww",
        "title": "Shubh - Bandana",
        "description": "Singer/Lyrics/Composer – Shubh Music Production – Sickboi Mix - mixedbyswitch , Gurjit Thind Master – mixedbyswitch Video ...",
        "thumbnails": {
            "default": {
                "url": "https://covers.djpunjab.pro/image/509801/Bandana-1.jpg",
                "width": 120,
                "height": 90
            }
        },
        "channelTitle": "SHUBH",
        "liveBroadcastContent": "none",
        "publishTime": "2024-03-29T03:38:04Z"
    }
},
{
  "kind": "youtube#searchResult",
  "etag": "D16xeSb6ieGlqsd9P2H797juOjA",
  "id": {
      "kind": "youtube#video",
      "videoId": "AFTIVN8rRbI"
  },
  "snippet": {
      "publishedAt": "2024-05-27T09:03:39Z",
      "channelId": "UCq-Fj5jknLsUf-MWSy4_brA",
      "title": "Sajni - Arijit Singh | Laapataa Ladies",
      "description": "Presenting the Full Video Song \"Sajni\" from the film Laapataa Ladies. Sung by Arijit Singh, Composed by Ram Sampath and ...",
      "thumbnails": {
          "default": {
              "url": "https://imgs.search.brave.com/KRhaTJhICDr0MPERYSbkEo-sjHYqq2ZI3tCNgZJuT4c/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jLnNh/YXZuY2RuLmNvbS8y/NTIvTGFhcGF0YWEt/TGFkaWVzLUhpbmRp/LTIwMjQtMjAyNDAy/MTMxNTEwMDQtNTAw/eDUwMC5qcGc",
              "width": 120,
              "height": 90
          }
      },
      "channelTitle": "T-Series",
      "liveBroadcastContent": "none",
      "publishTime": "2024-05-27T09:03:39Z"
  }
},
{
  "kind": "youtube#searchResult",
  "etag": "v2V9HXF2n1erOybXwt7ZdKua6Q4",
  "id": {
      "kind": "youtube#video",
      "videoId": "3u6lLWGjFLY"
  },
  "snippet": {
      "publishedAt": "2024-03-05T06:14:14Z",
      "channelId": "UCJrDMFOdv1I2k8n9oK_V21w",
      "title": "Naina - Diljit Dosanjh Ft. Badshah",
      "description": "Presenting The Latest Bollywood Romantic Full Song, 'Naina' From The Upcoming Movie 'Crew,' Featuring The Dynamic Trio Of ...",
      "thumbnails": {
          "default": {
              "url": "https://imgs.search.brave.com/wO0PfzFDtBxp0ILKcSI0kPH6Aor7LVJbr9m_5_scZVc/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9wYWdh/bGZyZWUuY29tL2lt/YWdlcy8xMjhOYWlu/YSUyMC0lMjBDcmV3/JTIwMTI4JTIwS2Jw/cy5qcGc",
              "width": 120,
              "height": 90
          }
      },
      "channelTitle": "Tips Official",
      "liveBroadcastContent": "none",
      "publishTime": "2024-03-05T06:14:14Z"
  }
},
];

const topSongsData = [
  {
    "kind": "youtube#searchResult",
    "etag": "fl8G0DlDQ0p-pQV2EP7wqnTbCLk",
    "id": {
        "kind": "youtube#video",
        "videoId": "vt0i6nuqNEo"
    },
    "snippet": {
        "publishedAt": "2024-02-22T17:00:43Z",
        "channelId": "UCF_fDSgPpBQuh1MsUTgIARQ",
        "title": "Popular - The Weeknd ft. Playboi Carti",
        "description": "Stream \"Popular\": http://theweeknd.co/PopularVD ▻Follow The Weeknd: Twitter: https://twitter.com/theweeknd Instagram: ...",
        "thumbnails": {
            "default": {
                "url": "https://imgs.search.brave.com/oEV9Cnb9v37GeU2h-f7m5bMhaBC8ErTmADlIlae1pKU/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pMS5z/bmRjZG4uY29tL2Fy/dHdvcmtzLVRHaVky/SlJ5UkZ6VHowTEst/UEhqS3dRLXQ1MDB4/NTAwLmpwZw",
                "width": 120,
                "height": 90
            }
        },
        "channelTitle": "TheWeekndVEVO",
        "liveBroadcastContent": "none",
        "publishTime": "2024-02-22T17:00:43Z"
    }
},
{
  "kind": "youtube#searchResult",
  "etag": "zu8iLIylorteESLLP4H-MajQqqE",
  "id": {
      "kind": "youtube#video",
      "videoId": "kTJczUoc26U"
  },
  "snippet": {
      "publishedAt": "2021-07-09T04:00:12Z",
      "channelId": "UCqPbkJrjrsQb_RGlC_4HEgg",
      "title": "Stay - The Kid LAROI, Justin Bieber",
      "description": "Official video for “Stay” by The Kid LAROI & Justin Bieber. Listen & Download “Stay” out now: https://thekidlaroi.lnk.to/Stay Amazon ...",
      "thumbnails": {
          "default": {
              "url": "https://imgs.search.brave.com/cJqWO-2hpP8Je-58xHUTer58CROkhlCqxSdT0EilVyQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tLm1l/ZGlhLWFtYXpvbi5j/b20vaW1hZ2VzL0kv/ODFqUnVEekJ5aVMu/anBn",
              "width": 120,
              "height": 90
          }
      },
      "channelTitle": "TheKidLAROIVEVO",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-09T04:00:12Z"
  }
},
{
  "kind": "youtube#searchResult",
  "etag": "oSopYKR4uavnd5VlTIuDKmlkOJM",
  "id": {
      "kind": "youtube#video",
      "videoId": "gJLVTKhTnog"
  },
  "snippet": {
      "publishedAt": "2023-11-30T14:30:08Z",
      "channelId": "UCafUh796DToiY2U3s7X_WTw",
      "title": "HUSN - Anuv Jain",
      "description": "The eleventh studio recorded single, written, sung and composed by #AnuvJain is here! Listen to 'Husn' on all streaming ...",
      "thumbnails": {
          "default": {
              "url": "https://imgs.search.brave.com/xY2ImYNDdsO15isFPgjgI78dJVidcYUXOzXi3-GL8kw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tLm1l/ZGlhLWFtYXpvbi5j/b20vaW1hZ2VzL00v/TVY1Qk5URmtNMkU1/WmprdFl6bGxNQzAw/WVROakxUaGtZakl0/WkRoaFptUmtORFZt/TmpVM1hrRXlYa0Zx/Y0dkZVFYVnlNelF4/TXpjeU9EUUAuanBn",
              "width": 120,
              "height": 90
          }
      },
      "channelTitle": "Anuv Jain",
      "liveBroadcastContent": "none",
      "publishTime": "2023-11-30T14:30:08Z"
  }
},
{
  "kind": "youtube#searchResult",
  "etag": "aLaR8NQFOtnrf_eoiY0ckv_s0xw",
  "id": {
      "kind": "youtube#video",
      "videoId": "VAdGW7QDJiU"
  },
  "snippet": {
      "publishedAt": "2023-08-14T06:19:10Z",
      "channelId": "UCq-Fj5jknLsUf-MWSy4_brA",
      "title": "Chaleya - Arijit Singh, Shilpa Rao",
      "description": "Witness the love with Chaleya, presenting the second song from the highly-awaited film Jawan directed by Atlee. The film stars ...",
      "thumbnails": {
          "default": {
              "url": "https://imgs.search.brave.com/61hyIOnov0h1p2eHuRExWFVp5Bml7Y3WDGSq0FM7NNo/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pMS5z/bmRjZG4uY29tL2Fy/dHdvcmtzLUNrWEtv/NWlKUTVhUFp6cDYt/WlJlcTJ3LXQ1MDB4/NTAwLmpwZw",
              "width": 120,
              "height": 90
          }
      },
      "channelTitle": "T-Series",
      "liveBroadcastContent": "none",
      "publishTime": "2023-08-14T06:19:10Z"
  }
},
{
  "kind": "youtube#searchResult",
  "etag": "1SU3Mt_usB_DH9pi64LF8Npnrsc",
  "id": {
      "kind": "youtube#video",
      "videoId": "ic8j13piAhQ"
  },
  "snippet": {
      "publishedAt": "2019-08-23T04:00:06Z",
      "channelId": "UCANLZYMidaCbLQFWXBC95Jg",
      "title": "Cruel Summer - Taylor Swift",
      "description": "Official audio for 'Cruel Summer' performed by Taylor Swift. Off her new album 'Lover.' Stream/Download the album here: ...",
      "thumbnails": {
          "default": {
              "url": "https://imgs.search.brave.com/PEo-fSnNF1qmdscK356oQ_yYTtC1YE6drU6VZEGhYZY/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90YXls/b3Jzd2lmdHN3aXR6/ZXJsYW5kLmNoL3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIwLzA4/L2FsYnVtY292ZXJh/cnQtMS5qcGc",
              "width": 120,
              "height": 90
          }
      },
      "channelTitle": "TaylorSwiftVEVO",
      "liveBroadcastContent": "none",
      "publishTime": "2019-08-23T04:00:06Z"
  }
},
];

const MyComponent3 = () => {
  const history = useHistory();

  useEffect(() => {
    // ReactGA4.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [selectedSongsId, setSelectedSongsId] = React.useState([]);
  const [selectedSongs, setSelectedSongs] = React.useState([]);
  const [songSource, setSongSource] = useState("landing"); // Default to 'landing'
  const [isSearching, setIsSearching] = useState(false); // State to track if searching
  const { menuOpen } = useMenu();

  const toggleMenu = () => {
    // setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    // Track menu toggle event
    ReactGA.event({
      category: "Navigation",
      action: "Toggle Menu",
      label: menuOpen ? "Close Menu" : "Open Menu",
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = (e) => {
    e.preventDefault(); // Prevent the button click from submitting the form
    setSearchQuery("");
    setSearchResults([]);
    setSelectedSongsId([]);
    setSelectedSongs([]);
    // Track search clear event
    ReactGA.event({
      category: "Search",
      action: "Clear Search",
      label: "Clear Search Query",
    });

    setSongSource("landing"); // Update source to 'search'
    setIsSearching(false);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await fetch(
      //   `https://scanthevibev1.el.r.appspot.com/api/youtube/search?q=${searchQuery}`
      // );
      //   const response = await fetch(`/api/youtube/search?query=${searchQuery}`);
      if (searchQuery.length > 3) {
        const response = await fetch(`http://localhost:5000/api/spotify/search?q=${searchQuery}&type=track`);
        const data = await response.json();
        const cleanedResults = cleanSearchResults(data.items);
        setSearchResults(cleanedResults); // Adjust based on your actual API response structure
        setIsSearching(true); // Indicate search results are present

        // Track search submit event
        ReactGA.event({
          category: "Search",
          action: "Submit Search",
          label: searchQuery,
        });

        setSongSource("search"); // Update source to 'search'
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      history.replace("/error");
    }
  };

  function cleanSearchResults(results) {
    const uniqueTitles = new Set();
    const cleanedResults = [];

    for (const result of results) {
      const cleanedTitle = cleanTitle(result.snippet.title);
      if (!uniqueTitles.has(cleanedTitle)) {
        uniqueTitles.add(cleanedTitle);
        cleanedResults.push({
          ...result,
          snippet: { ...result.snippet, title: cleanedTitle.song },
        });
      }
    }
    return cleanedResults;
    // return results.map((result) => {
    //   const cleaned = cleanTitle(result.snippet.title);
    //   return {
    //     ...result,
    //     snippet: {
    //       ...result.snippet,
    //       title: cleaned.song,
    //     },
    //   };
    // });
  }

  function cleanTitle(title) {
    // Regular expressions to identify and remove unwanted phrases and terms
    const patternsToRemove = [
      /official (music )?video/i,
      /lyric(s)? video/i,
      /\blyrics?\b/i, // Added pattern to remove 'lyric' or 'lyrics'
      /\blyrical\b/i, // Added pattern to remove 'lyrical'
      /\bHD\b/i,
      /\b4K\b/i,
      /[\(\[].*?[\)\]]/g, // Remove any content within parentheses or brackets
    ];

    // Iterate over each pattern and remove it from the title
    patternsToRemove.forEach((pattern) => {
      title = title.replace(pattern, "");
    });

    // Trim whitespace from the start and end of the title
    title = title.trim();

    // Remove leading special characters that are not typically part of a song title,
    // including colons
    title = title.replace(/^[^\w]+/, "").replace(/^\:/, "");

    // Replace encoded ampersand (&amp;) with the actual ampersand character
    title = title.replace(/&amp;/g, "&");

    title = he.decode(title);

    return { song: title }; // Default case if no dash is found
  }

  const handleToggleSelect = (event, song) => {
    event.preventDefault();
    event.stopPropagation();

    const videoId = song.id.videoId;
    const index = selectedSongs.findIndex(
      (song) => song.id.videoId === videoId
    );

    if (index !== -1) {
      setSelectedSongs((prevState) =>
        prevState.filter((s) => s.id.videoId !== videoId)
      );
    } else {
      setSelectedSongs((prevState) => [...prevState, song]);
      console.log(selectedSongs);
    }

    // Track song selection event
    ReactGA.event({
      category: "Song Selection",
      action: index !== -1 ? "Deselect Song" : "Select Song",
      label: song.snippet.title,
    });

    // setSelectedSongsId((prev) => {
    //   if (prev.includes(song.id.videoId)) {
    //     return prev.filter((songName) => songName.id.videoId !== song.id.videoId); // Remove id if already selected
    //   } else {
    //     return [...prev, song]; // Add id if not already selected
    //   }
    // });
  };

  const handleSendToQueue = async () => {
    const timestamp = new Date().toISOString();
    //change this line for environment change :NOTE
    const response = await fetch("https://scanthevibev1.el.r.appspot.com/queue/add", {
    // const response = await fetch("http://localhost:5000/queue/add", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ song: selectedSongs }),
    });
    if (response.status === 200) {
      toast.success("Song added! Keep the vibe going.");
      // Track successful song addition event
      ReactGA.event({
        category: "Queue",
        action: "Add Song to Queue",
        label: "Success",
        value: isSearching ? "Search Results" : "Landing Page",
      });
    } else {
      toast.error("Uh Oh! Lets try that again in a bit.");
      history.replace("/error");
      // Track failed song addition event
      ReactGA.event({
        category: "Queue",
        action: "Add Song to Queue",
        label: "Failure",
        value: isSearching ? "Search Results" : "Landing Page",
      });
    }
    const data = await response.json();
    console.log(data);
    setSelectedSongsId([]);
    setSelectedSongs([]);
  };

  useEffect(() => {
    if (menuOpen) {
      // Disable scrolling on the body by adding overflow hidden
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling by removing the overflow style
      document.body.style.overflow = "visible";
    }

    // Cleanup function to ensure the style is removed when component unmounts or updates
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [menuOpen]);

  return (
    <AppWrapper onClick={toggleMenu}>
      {/* <SideMenu $show={menuOpen}>
        <MenuItem to="/search">Search</MenuItem>
        <MenuItem to="/playlist">Queue</MenuItem>
      </SideMenu>
      <Header $menuOpen={menuOpen}>
        <MenuButton onClick={toggleMenu} />
        <SoundBar></SoundBar>
      </Header> */}

      {/* <Header>
        <SoundBar></SoundBar>
      </Header> */}

      <AppContent $menuOpen={menuOpen}>
        <SearchSection onSubmit={handleSearchSubmit}>
          <SearchBar>
            <SearchIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5ad0efe60d842d132bfbba6de074812774d978a00f0c3d2c92ade9ffa633aa8?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&"
              alt="Search icon"
            />
            <SearchInput
              type="text"
              placeholder="What do you want to listen to?"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {searchQuery && (
              <ClearButton onClick={handleClearSearch} type="button" />
            )}
          </SearchBar>
          <SearchResults>
            {searchResults.map((result) => (
              <SearchResultItemComponent
                key={result.id.videoId}
                result={result}
                onToggleSelect={handleToggleSelect}
                isSelected={selectedSongs.includes(result)}
              />
            ))}
          </SearchResults>
        </SearchSection>

        

        <ToastContainer />

        {!isSearching && (
          <LandingContainer>
            <SectionTitle>Recently Played</SectionTitle>
            <ItemList>
              {recentlyPlayedData.map((song) => (
                <SearchResultItemComponent
                  key={song.id.videoId}
                  result={song}
                  onToggleSelect={handleToggleSelect}
                  isSelected={selectedSongs.includes(song)}
                />
              ))}
            </ItemList>
            <SectionTitle>Top Songs</SectionTitle>
            <ItemList>
              {topSongsData.map((song) => (
                // <Item key={song.id}>
                //   <ItemImage src={song.image} alt={song.title} />
                //   <ItemText>
                //     {song.title} - {song.artist}
                //   </ItemText>
                // </Item>
                <SearchResultItemComponent
                  key={song.id.videoId}
                  result={song}
                  onToggleSelect={handleToggleSelect}
                  isSelected={selectedSongs.includes(song)}
                />
              ))}
            </ItemList>
          </LandingContainer>
        )}

<AddToQueueButton
          onClick={handleSendToQueue}
          $isVisible={selectedSongs.length > 0} // Button only appears if there's at least one song selected
        >
          Send my suggestions
        </AddToQueueButton>
      </AppContent>
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  // background-image: url(${uiBackgroundImg});
  background-color: #141414;
  background-size: cover;
  background-position: center;
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  height:100vh;
`;

const SoundBar = styled.div`
  background: url(${soundBarImg}) no-repeat center center;
  background-size: cover;
  border: none;
  margin-left: 15%;
  width: 70%;
  height: 40px;
  cursor: pointer;
  left: 0px;
`;

const MenuButton = styled.button`
  background: url(${menuIcon}) no-repeat center center;
  background-size: cover;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: fixed;
`;

const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 16px;
  &:hover {
    background-color: #292929;
  }
`;

const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 5%;
  width: 250px;
  height: 100%;
  background: #141414;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) =>
    props.$show ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  z-index: 999;
`;

const AppContent = styled.div`
  transition: transform 0.3s ease-in-out;
  padding-left: ${(props) => (props.$show ? "250px" : "0")};
  background-color: #141414;
  display: flex;
  width: 100%;
  flex-direction: column;
  //margin-top: 60px; // Adjust according to the height of your header
  overflow: ${(props) => (props.$menuOpen ? "hidden" : "auto")};
  padding-bottom: 100px;
`;

const Header = styled.header`
  top: 0;
  left: ${(props) =>
    props.$menuOpen ? "250px" : "0"}; // Adjust position based on menu state
  right: 0;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 30px;
  z-index: 1000;
  width: 100% // Adjust width based on menu state
  transition: transform 0.3s ease-in-out;// Smooth transition for the movement
`;

// const MenuIcon = styled.img`;
//   width: 35px;
// `;

const SearchSection = styled.form`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 70px;
  position: fixed;
  background-color: #141414;
  width: 86%;
`;

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px;
  width: 100%;
`;

const SearchIcon = styled.img`
  width: 24px;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
`;

const ClearButton = styled.button`
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const ClearIcon = styled.img`
  width: 20px;
  z-index: 100;
  // color: black;
`;

// const SearchResults = styled.div`
//   margin-top: 20px;
// `;

// const SearchResultItem = styled.div`
//   background-color: #f8f8f8;
//   border-radius: 10px;
//   margin-top: 10px;
//   padding: 10px;
//   display: flex;
//   align-items: center;
// `;

// const ResultImage = styled.img`
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   margin-right: 10px;
// `;

// const ResultText = styled.span`
//   font-size: 16px;
// `;

const LandingContainer = styled.div`
  margin-top: 110px;
`

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 12px;
  padding: 5px;
  background-color: #141414; // Dark background
`;

const SearchResultItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #292929; // Slightly highlight on hover
  }
`;

const SearchResultItemComponent = ({ result, onToggleSelect, isSelected }) => {
  return (
    <SearchResultItem onClick={(event) => onToggleSelect(event, result)}>
      <ResultImage
        src={result.snippet.thumbnails.default.url}
        alt="Video Thumbnail"
      />
      <ResultText>{result.snippet.title}</ResultText>
      <SelectButton
        type="button"
        onClick={(event) => onToggleSelect(event, result)}
      >
        {isSelected ? <FaCheck style={{ color: "#FFBA33" }} /> : <FaPlus />}
      </SelectButton>
    </SearchResultItem>
  );
};

const SelectButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultImage = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 50%; // Make the image rounded
`;

const ResultText = styled.div`
  font-size: 16px;
  color: #ccc; // Light gray text for better readability
  flex-grow: 1;
`;

const SectionTitle = styled.h2`
  color: #fff;
  padding: 10px 16px;
  font-size: 18px;
  margin-bottom: 0px;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 10px;
`;

const ItemText = styled.span`
  color: #fff;
  font-size: 16px;
`;

const AddToQueueButton = styled.button`
  position: fixed;
  bottom: 20px; // Keeps the button at the bottom of the viewport
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to bottom right, #FFBA33, #664A14); // Gradient from #FFC629 to black
  color: white;
  border: none;
  border-radius: 40px;
  padding: 12px 20px; // Comfortable padding
  width: 90%; // Almost full width of the screen
  font-size: 16px; // Bold and readable font size
  font-weight: bolder;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Slightly stronger shadow for depth
  transition: background-color 0.3s, opacity 0.3s;
  display: ${(props) => (props.$isVisible ? "block" : "none")};

  &:active {
    background: linear-gradient(90deg, rgba(69, 160, 73, 1), black); // Darker gradient when tapped or clicked
    opacity: 0.9; // Slightly less opaque to simulate a press effect
  }

  &:focus {
    outline: none; // Avoids the default focus outline
  }
`;

export default MyComponent3;
